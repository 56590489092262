<template>
  <div>
    <section class="create-company">
      <div>
        <p class="main-title text-center">
          Get Listed

          <!-- <router-link to="/other-getlisted">
            <v-btn class="float-right" color="primary">
              Add another company
            </v-btn></router-link
          > -->
        </p>
      </div>
      <v-container>
        <v-stepper v-model="curr">
          <v-stepper-header>
            <template v-for="(step, n) in steps">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="stepComplete(n + 1)"
                :step="n + 1"
              >
                {{ step.name }}
              </v-stepper-step>
              <v-divider v-if="n != 5" :key="n"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="(step, n) in steps"
              :key="`${n}-content`"
              :step="n + 1"
            >
              <component
                :ref="step.value"
                :is="step.value"
                :companydata="companydata"
                :companyportfolio="companyportfolio"
                :companyaddress="companyaddress"
                :companyServiceLine="companyServiceLine"
                :companyIndustryFocus="companyIndustryFocus"
                :companyClientSize="companyClientSize"
                :companySpecialization="companySpecialization"
                @info="submitStepInfo"
              ></component>

              <v-row class="create-footer">
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    @click="previous(curr)"
                    v-if="curr != 1"
                  >
                    Previous
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    class="float-right"
                    color="primary"
                    :disabled="
                      curr == 4
                        ? categoryTotalPercentage !== 100 ||
                          specializationTotalPercentage !== 100 ||
                          industryFocusTotalPercentage !== 100 ||
                          clientTotalPercentage !== 100
                        : errors.any()
                    "
                    @click="next(n)"
                    v-if="curr != 6"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    class="float-right"
                    color="primary"
                    @click="goToDashboard()"
                    v-if="curr == 6"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>

            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
         <div class="mt-3 alert alert-success alert-dismissible fade show" v-if="successfull">
        <strong>Success!</strong> All Data has been submit successfully
    </div>
      </v-container>
      
           
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BasicInfo from "../../components/BasicInfo.vue";
import Location from "../../components/Location.vue";
import Leads from "../../components/Leads.vue";
import Focus from "../../components/Focus.vue";
import Portfolio from "../../components/Portfolio.vue";
import AdminInfo from "../../components/AdminInfo.vue";

export default {
  components: {
    BasicInfo,
    Location,
    Leads,
    Focus,
    Portfolio,
    AdminInfo,
  },
  name: "User",
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  data: function () {
    return {
      curr: 1,
      successfull:false,
      steps: [
        { name: "Basic Info", value: "BasicInfo" },
        { name: "Location", value: "Location" },
        { name: "Leads", value: "Leads" },
        { name: "Focus", value: "Focus" },
        { name: "Portfolio", value: "Portfolio" },
        { name: "Admin Info", value: "AdminInfo" },
      ],
      loading: false,
      slide: 0,
      sliding: null,
      submitted: false,
      companydata: null,
      companyportfolio: null,
      companyServiceLine: null,
      companySpecialization: null,
      companyClientSize: null,
      companyIndustryFocus: null,
      companyaddress: null,
      specializationTotalPercentage: 0,
      clientTotalPercentage: 0,
      categoryTotalPercentage: 0,
      industryFocusTotalPercentage: 0,
      companyAllData: {
        basicInfo: null,
        locationInfo: null,
        leadsInfo: null,
        adminInfo: null,
        focus_info_clientsize: null,
        focus_info_specialization: null,
        focus_info_category: null,
      },
      portfoilio: [],
    };
  },

  mounted() {
    this.userId = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    if (!this.userId) {
      this.$router.push({ name: "login" });
    }
  },
  created() {
    this.getCompanyData();
    this.loading = true;
  },

  methods: {
    ...mapActions([
      "sendCreateCompanyData",
      "sendAddressDate",
      "sendUpdateCompanyData",
      "getUserData",
      "addCompanyPortfolio",
      "sendSpecialization",
      "sendClientSize",
      "getSpecialization",
      "sendIndustryFocus",
      "sendCategory",
      "removeCompanyPortfolio",
    ]),
    async getCompanyData() {
      this.loading = true;
      await this.getUserData()
        .then((res) => {
          // res.data.company_slug.company_logo =
          //   process.env.VUE_APP_URL +
          //   "images/company/" +
          //   res.data.company_slug.company_logo;
          // console.log("res.data.company_slug", res.data.company_slug);
          this.companydata = res.data.company_slug;
          this.companyportfolio = res.data.company_portfoilio;
          this.companyaddress = res.data.company_address;
          console.log(this.companyaddress)
          this.companyServiceLine = res.data.companyServiceLine;
          this.companySpecialization = res.data.companySpecialization;
          this.companyClientSize = res.data.companyClientSize;
          this.companyIndustryFocus = res.data.companyIndustryFocus;
        })
        .finally(() => (this.loading = false));
    },
    submitStepInfo(data) {
      if (data.type == "basic_info") {
        this.companyAllData.basicInfo = data.data;
      } else if (data.type == "location_info") {
        this.companyAllData.locationInfo = data.data;
      } else if (data.type == "leads_info") {
        this.companyAllData.leadsInfo = data.data;
      } else if (data.type == "portfolio_info") {
        this.portfoilio = data.data;
        console.log(this.portfoilio);
      } else if (data.type == "admin_info") {
        this.companyAllData.adminInfo = data.data;
      } else if (data.type == "focus_info_specialization") {
        this.companyAllData.focus_info_specialization = data.data;
        this.specializationTotalPercentage = data.specializationTotalPercentage;
      } else if (data.type == "focus_info_clientsize") {
        this.companyAllData.focus_info_clientsize = data.data;
        this.clientTotalPercentage = data.clientTotalPercentage;
      } else if (data.type == "focus_info_category") {
        this.companyAllData.focus_info_category = data.data;
        this.categoryTotalPercentage = data.categoryTotalPercentage;
      } else if (data.type == "focus_info_industryFocus") {
        this.companyAllData.focus_info_industryFocus = data.data;
        this.industryFocusTotalPercentage = data.industryFocusTotalPercentage;
      }
    },
    stepComplete(step) {
      return this.curr > step;
    },
    stepStatus(step) {
      return this.curr > step;
    },
    async next(n) {
      let companyObj = this.companydata;
      if (this.curr == 1) {
        ///console.log(companyObj);
        this.$refs.BasicInfo[0].$validator.validateAll().then((result) => {
          if (result) {
            if (companyObj == null) {
              this.sendCreateCompanyData(this.companyAllData.basicInfo).then(
                (response) => {
                  localStorage.setItem("slug", response.data.data.slug);
                }
              );
            } else {
              this.sendUpdateCompanyData(this.companyAllData).then(
                (response) => {
                   localStorage.setItem("slug", response.data.data.slug);
                  console.log(response);
                }
              );
            }
            this.curr = n + 2;
          }
        });
        // console.log(this.companyAllData);
      } else {
        if (this.curr == 2) {
          this.$refs.Location[0].$validator.validateAll().then((result) => {
            if (result) {
              console.log(localStorage.getItem("slug"));
              this.sendAddressDate({
                slug: localStorage.getItem("slug"),
                country: this.companyAllData.locationInfo.country,
                city: this.companyAllData.locationInfo.city,
                zip_code: this.companyAllData.locationInfo.zip_code,
                address: this.companyAllData.locationInfo.address,
                phone_number: this.companyAllData.locationInfo.phone_number,
                employee_count: this.companyAllData.locationInfo.employee_count,
              }).then((response) => {
                console.log(response);
              });
              this.curr = n + 2;
            }
          });
        } else if (this.curr == 3 || this.curr == 6) {
          if (this.curr == 3) {
            this.$refs.Leads[0].$validator.validateAll().then((result) => {
              if (result) {
                this.sendUpdateCompanyData(this.companyAllData).then(
                  (response) => {
                    console.log(response);
                  }
                );
                this.curr = n + 2;

              }
            });
          }
          if (this.curr == 6) {
            this.$refs.AdminInfo[0].$validator.validateAll().then((result) => {
              if (result) {
                this.sendUpdateCompanyData(this.companyAllData).then(
                  (response) => {
                    console.log(response);
                  }
                );
                this.curr = n + 2;
              }
            });
          }
        } else if (this.curr == 5) {
          this.$refs.Portfolio[0].$validator.validateAll().then((result) => {
            if (result) {
              this.curr = n + 2;
            }
          });
        } else if (this.curr == 4) {
          //let sep = null;
          // this.getSpecialization().then((resp) => {
          //   resp.data.data.forEach((res) => {
          //     let sname = res.name;
          //     let id = res.id;
          //     //  this.companyAllData.focusInfo.forEach((value)=>{
          //     for (let i in this.companyAllData.focusInfo.specialization) {
          //       if (sname == i) {
          //         if (this.companyAllData.focusInfo.specialization[i] != "") {

          //           sep['specializations_id'] =id
          //          sep['percentage'] =this.companyAllData.focusInfo.specialization[i];
          //         }
          //       }
          //     }
          //     //  })
          //   });
          // });
          // console.log(23);
          // this.companyAllData.focusInfo.specialization.forEach((element) => {
          //   console.log("specializations_id=", Object.keys(element));
          //   console.log("percentage=", element[Object.keys(element)]);
          // });

          this.$refs.Focus[0].$validator.validateAll().then((result) => {
            if (result) {
              if (
                this.companyAllData.focus_info_category &&
                this.companyAllData.focus_info_category.category
              ) {
                this.sendCategory(
                  this.companyAllData.focus_info_category.category
                ).then((response) => {
                  console.log(response);
                });
              }
              if (
                this.companyAllData.focus_info_specialization &&
                this.companyAllData.focus_info_specialization.specialization
              ) {
                this.sendSpecialization(
                  this.companyAllData.focus_info_specialization.specialization
                ).then((response) => {
                  console.log(response);
                });
              }
              if (
                this.companyAllData.focus_info_clientsize &&
                this.companyAllData.focus_info_clientsize.clientsize
              ) {
                console.log(  this.companyAllData.focus_info_clientsize.clientsize  )
                this.sendClientSize(
                
                  this.companyAllData.focus_info_clientsize.clientsize
                ).then((response) => {
                  console.log(response);
                });
              }
              if (
                this.companyAllData.focus_info_industryFocus &&
                this.companyAllData.focus_info_industryFocus.industryFocus
              ) {
                this.sendIndustryFocus(
                  this.companyAllData.focus_info_industryFocus.industryFocus
                ).then((response) => {
                  console.log(response);
                });
              }
              this.curr = n + 2;
            }
          });
        }
      }
    },
    previous(n) {
      this.curr = n - 1;
    },
    handleSubmit() {
      this.submitted = true;
      this.$validator.validate().then((valid) => {
        if (valid) {
          alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.user));
        }
      });
    },
    goToDashboard() {
      this.sendUpdateCompanyData(this.companyAllData).then(() => {
       // window.location.reload();
        this.successfull=true;
      
         let i=this;
         setTimeout(function(){
                    i.successfull= false;
                    
                }, 2000);

             
      });
    },
  },
};
</script>
<style scoped>
.create-company {
  width: 70%;
  margin: 50px auto;
}
.v-application .primary {
  background-color: #242b34 !important;
  border-color: #242b34 !important;
}
</style>
